import React from "react";

export const generateLines = () => {
  let lines = [];
  const rows = 40;

  for (let i = rows; i > 0; i--) {
    const cellHeight = rows - i;
    const cellOpacity = ((100 / rows) * i) / 100;

    const lineStyles = {
      opacity: cellOpacity,
      height: `${cellHeight}px`,
    };

    lines.push(<div key={i} style={lineStyles} className="line"></div>);
  }

  return lines;
};
