import React from "react";
import logo from "../../RadWolf.png";
import YouTubeEmbed from "../YouTubeEmbed/YouTubeEmbed";
import ProfileLink from "../ProfileLink/ProfileLink";
import "./Home.css";
import { generateLines } from "../../utils/generateLines";

const Home = () => {
  return (
    <div className="main">
      <div className="graphic">{generateLines()}</div>
      <header>
        <img src={logo} className="logo" alt="Wolf logo" />
        <h1>RadWolf 3D Printing</h1>
        <h2 className="contact">
          Contact to order{" "}
          <a className="email" href="mailto: radwolf@radwolf3dprinting.com">
            radwolf@radwolf3dprinting.com
          </a>
        </h2>
      </header>

      <section>
        <p className="about">
          After several years of 3D printing items for family and friends, we
          are now taking on commission projects. Contact us at the email above
          to order or request a custom model. If you already have an STL, send
          us an email with the STL attached and your color and size preferences.
        </p>
        <p className="about">
          Pricing is determined on a case-by-case basis. Please contact us to
          receive your estimate.
        </p>
      </section>
      
      <section>
        <ProfileLink />
      </section>

      <section>
        <h2>Timelapse</h2>
        <YouTubeEmbed embedId="videoseries?list=PL6bK2z5kjIEu5whX5syiUAKngIsvOOlBP" />
      </section>

      <footer>
        <div>© 2022-2024 RadWolf 3D Printing</div>
        <div>
          Website by{" "}
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://katieradford.dev/"
            className="dev-link"
          >
            Katie Radford
          </a>
        </div>
      </footer>
    </div>
  );
};

export default Home;
