import React from "react";
import "./ProfileLink.css";

const Home = () => {
  return (
    <div className="profile-list">
      <a className="profile" target="_blank" rel="noopener noreferrer" href="https://www.thingiverse.com/radwolf/designs">
          Thingiverse Models
      </a>
      <a className="profile" target="_blank" rel="noopener noreferrer" href="https://makerworld.com/en/u/3493517036">
          MakerWorld Models
      </a>
      <a className="profile" target="_blank" rel="noopener noreferrer" href="https://www.printables.com/@RadWolf_1279619">
          Printables Models
      </a>
    </div>
  );
};

export default Home;
